jQuery.noConflict();
(function( $ ) {
  $(function() {
      $( document ).ready(function() {



          $('.js-toggleMenuItem').on('click', function(e) {
              e.preventDefault();

              var $clicked = $(this);
              var $item = $clicked.parents('.c-expand-menu__item');

              $clicked.toggleClass('is-active');
              $item.first().toggleClass('is-active');
          });



      });

  });
})(jQuery);

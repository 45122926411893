jQuery( document ).ready(function( $ ) {

    //*****************************************
    // Mobile Nav - requires plugins/bodyscrolllock.js
    //*****************************************


    var $body = $(document.body);
    var $mobileNavDrawer = document.querySelector("#site-header-nav");

    var $mobileNav = $('#site-header-nav'),
        $mobileNavTarget = $('.js-mobileNavTarget'),
        $mobileNavToggle = $('.js-mobileNavToggle'),
        activeClass = 'is-active';

    function toggleMobileNav() {
        if ( $mobileNav.hasClass(activeClass) ) {
            deactivateMobileNav();
        } else {
            activateMobileNav();
        }
    }

    //removes active class from .js-mobileNavTarget elements
    //enables body scroll again
    function deactivateMobileNav() {
        $mobileNavTarget.removeClass( activeClass );
        bodyScrollLock.enableBodyScroll( $mobileNavDrawer );
    }

    //adds active class // TODO:  .js-mobileNavTarget elements
    //disables body scroll
    function activateMobileNav() {
        $mobileNavTarget.addClass( activeClass );
        bodyScrollLock.disableBodyScroll( $mobileNavDrawer );
    }


    //--------------------------------------------------------------------
    //   Doc Ready - There shouldnt be more than one of these in this file
    //--------------------------------------------------------------------

    $(document).ready(function(e){
        $mobileNavToggle.click(function() {
            toggleMobileNav();
        })
    });

});

(function ($) {
  $(document).ready(function () {


    if ($('.js-imageLightbox').length) {
      $('.js-imageLightbox').magnificPopup({
        type: 'image',
        mainClass: 'c-lightbox c-lightbox--image',
        image: {
          verticalFit: false
        }
      });
    }


    if ($('.js-modalLightbox').length) {
      $('.js-modalLightbox').magnificPopup({
        type: 'inline',
        closeBtnInside: true,
        mainClass: ['c-lightbox', 'c-lightbox--modal'].join(' '),
        callbacks: {
          beforeOpen: function () {
            this.st.mainClass += ' ' + $(this.ev).data('mfpClass');
          }
        }
      });
    }



    // Video Lightbox
    if ($('.js-videoLightbox').length) {

      // Load wistia in an iframe on video click, and if the element has a CTA, show/hide during video playing.
      $('.js-videoLightbox').magnificPopup({
        type: 'inline',
        mainClass: 'c-lightbox c-lightbox--video',
        callbacks: {
          elementParse: function (item) {
            item.src = '<div class="mfp-iframe-scaler"><button title="Close (Esc)" type="button" class="mfp-close">×</button><iframe src="https://fast.wistia.net/embed/iframe/' + item.el.data('vid') + '?autoPlay=true&silentAutoPlay=allow" allowtransparency="true" frameborder="0" scrolling="no" class="wistia_embed" name="wistia_embed" allow="autoplay" allowfullscreen mozallowfullscreen webkitallowfullscreen oallowfullscreen msallowfullscreen width="640" height="360"></iframe></div><script src="//fast.wistia.com/assets/external/E-v1.js" async></script>';
          },
          open: function () {
            var $cta = $('#video-cta--' + $(this.currItem.el).data('vid'));
            if ($cta.length) {
              $cta.removeClass('is-active');
            }
          },
          close: function () {
            var $cta = $('#video-cta--' + $(this.currItem.el).data('vid'));
            if ($cta.length) {
              $cta.addClass('is-active');
            }
          }
        }
      });

      // If there are CTAs, make sure close button works.
      if ($('.js-closeVideoCTA').length) {
        $('.js-closeVideoCTA').on('click', function (e) {
          var $cta = $('#video-cta--' + $(this).data('vid'));
          //console.log('#video-cta--' + $(this).data('vid'));
          $cta.removeClass('is-active');
        })
      }

    } // End Video Lightbox Code



  });
})(jQuery);
